.ticker {
      background-color: #fff;
      border: 0 solid #e2e8f0;
      border-radius: 1rem;
      box-sizing: border-box;
      color: #0d172a;
      cursor: pointer;
      display: inline-block;
      font-family: "Basier circle",-apple-system,system-ui,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
      font-size: 0.9rem;
      font-weight: 600;
      line-height: 1;
      padding: 0.9rem 0.9rem;
      text-align: center;
      text-decoration: none #0d172a solid;
      text-decoration-thickness: auto;
      transition: all .1s cubic-bezier(.4, 0, .2, 1);
      box-shadow: 0px 1px 2px rgba(166, 175, 195, 0.25);
      user-select: none;
      -webkit-user-select: none;
      touch-action: manipulation;
}
    
.ticker:hover {
      background-color: #1e293b;
      color: #fff;
 }
    
@media (min-width: 768px) {
    .ticker {
    font-size: 0.9rem;
    padding: 0.9rem 0.9rem;
    }
}


.noBorder {
	background-color:transparent;
	border-radius:15px;
	border: none;
	display:inline-block;
	cursor:pointer;
	color:#ffffff;
	font-family:Arial;
	font-size:17px;
	/* padding:16px 31px; */
	text-decoration:none;
	text-shadow:0px 1px 0px #000000;
}
.noBorder:hover {
	background-color:transparent;
}
.noBorder:active {
	position:relative;
	top:1px;
}
