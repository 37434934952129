.blurDiv {
    -webkit-filter: blur(5px);
    -moz-filter: blur(5px);
    -o-filter: blur(5px);
    -ms-filter: blur(5px);
    filter: blur(5px);
    width: 100%;
    min-height: 33.333333333%;
    max-height: 33.333333333%;
    background-color: rgba(58, 65, 63, 0.18);
    overflow-y: scroll;
}

.top_range {
    background-color: rgba(255,255,255, 0.9);
    min-height: 33.333333333%;
    max-height: 33.333333333%;
    border-color: aqua;
    overflow-y: scroll;
}

.mid_range {
    background-color: rgba(255,255,255, 0.9);
    min-height: 33.333333333%;
    max-height: 33.333333333%;
    border-color: aqua;
    overflow-y: scroll;
}

.bottom_range {
    background-color: rgba(255,255,255, 0.9);
    min-height: 33.333333333%;
    max-height: 33.333333333%;
    border-color: aqua;
    overflow-y: scroll;
}

.content {
    font-size: 25px;
    height: 95vh;
    background-image: url("sine_graph.png");
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
}

ol {
    -moz-column-count: 2;
    -moz-column-gap: 20px;
    -webkit-column-count: 2;
    -webkit-column-gap: 20px;
    column-count: 3;
    column-gap: 20px;
}