.App {
  max-width: 1300px;
  width: 1300px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.pCenter {
  text-align: center;
}

.center {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}

.searchBar {
  height: 38px;
  background-color: coral;
}

.contentWrapper {
  max-height: 100%;
}

.centerHorizontally {
  width: 100%;
  height: 30px;
  align-items: center;
  display: flex;
}

.companyName {
  /* margin:auto; */
  margin-left: 8px;
  height: 1.2em;
  font-size: 1.2em;
  text-align: center;
  float:left;
}

.Chart {
  width: 100%;
  height: 100%;
}

.rrgInfo {
  margin-left: 8px;
  height: 1.2em;
  float: left;
  text-align: center;
  font-size: 0.8em;
}

.selectDrawingToolButton {
  float:left;
}

.logoutButton {
  float:left;
}

.infoButton {
  float:left;
}

.saveButton {
  margin-left: 8px;
  float:left;
  height: 1.2em;
  width: 1em;
}


.centerImage {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}
